<template>
    <v-col cols="12">
        <v-img v-if="isImage && imageUrl" :src="imageUrl" contain :max-width="maxWidth" :max-height="maxHeight"/>
        <v-file-input show-size :label="label" @change="onFilePicked"
                      :prepend-icon="isImage? 'mdi-camera':'mdi-paperclip'"/>
        <v-btn dark v-on:click="sendFile">Envoyer</v-btn>
        <v-alert v-model="alert" type="success" dismissible>Envoyé avec succès</v-alert>
    </v-col>
</template>

<script>
    import {isImageExtension} from "../utils/filesType";
    import {ENTRYPOINT} from "../config/entrypoint";
    import {mapFields} from "vuex-map-fields";
    import {mapActions} from "vuex";
    import NotificationMixin from "../mixins/NotificationMixin";

    export default {
        name: "MediaObject",
        mixins: [NotificationMixin],
        props: {
            label: {
                type: String
            },
            value: {
                type: Object
            },
            fileField: {
                type: String
            },
            isImage: {
                type: Boolean,
                default: false
            },
            maxWidth: {
                type: String,
            },
            maxHeight: {
                type: String
            }
        },
        data() {
            return {
                imageName: '',
                imageUrl: '',
                imageFile: '',
                imageId: '',
                error:'',
                alert: false
            };
        },
        mounted() {
            this.alert=false
        },
        watch: {
            'value': function () {
                if (this.value) {
                    this.imageId = this.value['@id']
                    this.imageUrl = ENTRYPOINT + this.value.contentUrl
                }
            },
            error(message) {
                message && this.showError(message);
            }
        },
        computed: {
            ...mapFields('userparameters', {
                token: 'token',
            }),
        },
        methods: {
            // ...mapActions('userparameters', {
            //     resetLogin : 'resetToken',
            // }),
            isImageExtension,
            onFilePicked(file) {
                this.alert=false
                if (file !== undefined) {
                    this.imageName = file.name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(file)
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = file // this is an image file that can be sent to server...
                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },
            sendFile() {
                let formData = new FormData
                formData.append("file", this.imageFile);
                formData.append("id_to_replace",this.imageId)
                // let options = {
                //     method: "POST",
                //     headers: {
                //         'Authorization': "Bearer " + this.token,
                //         'accept': 'application/ld+json'
                //     },
                //     body: formData
                // };
                let options = {
                    method: "POST",
                    headers: {
                        'Authorization': "Bearer " + this.token,
                        'accept': 'application/ld+json'
                    },
                    body: formData
                };
                let sendUrl = ENTRYPOINT
                if (this.imageId) {
                    sendUrl += this.imageId
                } else {
                    sendUrl += '/media_objects'
                }

                global.fetch(sendUrl, options).then(response => {
                    if (response.ok) {
                        this.alert=true
                        return response.json()
                    }
                    // if (response.status === 401) {
                    //     this.resetLogin()
                    //     throw new Error('Login error');
                    // }
                    throw new Error('System error');
                }).then(data => {
                    this.$emit('input', data)
                }).catch(error => {
                    this.error=error
                    console.error(error)
                });
            },
        }
    }
</script>

<style scoped>

</style>